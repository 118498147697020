import React, { useEffect } from "react";
import { selectType } from "@src/store/modules/typeSlice";
import { useSelector } from "react-redux";
import { Header } from "@src/components/common/Header";

const ImpossiblePage = ({ history }) => {
  const type = useSelector(selectType);
  useEffect(() => {
    document.title = "삼천리 | 이용 불가 시간 안내";
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);
  return (
    <>
      <Header title="이용 불가 시간 안내" />

      <div id="container" className="container">
        <div className="section_error">
          <div className="icon_area3" />
          <p className="txt">안녕하세요, 삼천리입니다.</p>

          <p className="error_txt center">본 서비스는 22:00 ~ 06:00에는</p>
          <p className="error_txt center">이용이 불가하오니,</p>
          <p className="error_txt center">이점 양해 부탁 드립니다.</p> 
          {/* <p className="error_txt center2">시스템 점검으로 셀프전출입 이용이 일시적으로 불가능합니다. </p>
          <p className="error_txt center">고객님께 불편을 드려 죄송합니다. </p>
          <p className="error_txt center">고객센터로 문의해 주세요 ☎1544-3002</p>
          <p className="error_txt center">- 점검기간 : 12월 19일(목) 20:00 ~ 22:00</p> */}
        </div>
        {type.kakao === "inapp" ? (
          <button
            type="submit"
            className="btn btn_blue btn_big"
            onClick={() => {
              sessionStorage.clear();
              if (navigator.userAgent.includes("iPhone")) {
                // 카카오 인앱 아이폰의 경우 닫기
                // window.location.href = "kakaoweb://closeBrowser";
                window.location.href = process.env.REACT_APP_KAKAO_SELFMOVE;
              } else {
                // 카카오 인앱에서 안드로이드로 접근했던 경우
                // window.location.href = "http://pf.kakao.com/_dDxmzu/chat";
                window.location.href = process.env.REACT_APP_KAKAO_SELFMOVE;
              }
            }}
          >
            확인
          </button>
        ) : // 그 외에 버튼 없음
        null}
      </div>
    </>
  );
};

export default ImpossiblePage;
